import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/newuser',
    name: 'NewUser',
    component: () => import('../views/NewUser.vue')
  },
  {
    path: '/',
    name: 'Default',
    component: () => import('../views/Screensaver.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/mentality',
    name: 'Mentality',
    component: () => import('../views/Mentality.vue')
  },
  {
    path: '/mentalitycate',
    name: 'MentalityCate',
    component: () => import('../views/MentalityCate.vue'),
    children: [
      {
        path: '/mentality/videolist',
        name: 'VideoList',
        component: () => import('../views/mentality/VideoList.vue')
      },
      {
        path: '/mentality/mangalist',
        name: 'MangaList',
        component: () => import('../views/mentality/MangaList.vue')
      },
      {
        path: '/mentality/compositionlist',
        name: 'CompositionList',
        component: () => import('../views/mentality/CompositionList.vue')
      },
      {
        path: '/mentality/q&a',
        name: 'Qa',
        component: () => import('../views/mentality/q&a.vue')
      },
    ]
  },
  {
    path: '/compositionshow',
    name: 'CompositionShow',
    component: () => import('../views/CompositionShow.vue')
  },
  {
    path: '/servicecenter',
    name: 'ServiceCenter',
    component: () => import('../views/ServiceCenter.vue')
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment.vue')
  },
  {
    path: '/screensaver',
    name: 'Screensaver',
    component: () => import('../views/Screensaver.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/musicplayer',
    name: 'MusicPlayer',
    component: () => import('../views/MusicPlayer.vue'),
  },
  {
    path: '/returnbefore',
    name: 'ReturnBefore',
    component: () => import('../views/ReturnBefore.vue'),
  }
]

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

// export default router


const router = createRouter({
  history: createWebHashHistory(),
  routes
})
 
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if(isChunkLoadFailed){
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
      location.reload();
      // const targetPath = $router.history.pending.fullPath;
      // $router.replace(targetPath);
  }
  
});
 
export default router
