const baseSize = 10;

function setRem() {
    let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    const scale = clientWidth / 1920;
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}

setRem();
window.addEventListener("resize", function() {
    // clearTimeout(tid); //防止执行两次
    // tid = setTimeout(setRem, 300);
    setRem();
}, false);