<template>
    <div class="home">
        <div class="login_left">
            <div class="logo" @click="toIndex">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <div class="centerbox">
                <div class="titlebox">
                    <div class="atitle">{{ base.webName }}</div>
                    <div class="btitle">{{ base.webFax }}</div>
                </div>
                <div class="login">
                    <div class="login_main">
                        <div class="catebox">
                            <div class="cate" :class="{ active: loginType === 1 }" @click="changeType(1)">微信登录</div>
                            <div class="cate" :class="{ active: loginType === 2 }" @click="changeType(2)">账户登录</div>
                        </div>
                        <div class="formbox" v-if="loginType === 1">
                            <div class="qrbox">
                                <div class="picbox">
                                    <div class="pic"
                                        style="overflow: hidden;display: flex;justify-content: center;align-items: center;">
                                        <img style="width: 120%;height: 120%;"
                                            :src="baseUrl + 'api/qrcode?url=' + wxLoginUrl + '&pixel=10'" alt="">
                                    </div>
                                    <!-- <div class="pic" style="background-image: url(/img/qrcode.png)"></div> -->
                                </div>
                            </div>
                            <div class="tishi">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/scan.png)"></div>
                                </div>
                                <div class="txt">
                                    请使用<span>微信客户端</span>扫码登录
                                </div>
                            </div>
                        </div>
                        <div class="formbox" v-if="loginType === 2">
                            <div class="input" v-bind:class="{ 'active': username_sw }">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/username.png)"></div>
                                </div>
                                <input type="text" maxlength="20" placeholder="请输入您的用户名" v-model="username">
                            </div>
                            <div class="input" v-bind:class="{ 'active': password_sw }">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/password.png)"></div>
                                </div>
                                <input type="password" maxlength="20" placeholder="请输入您的密码" v-model="password">
                            </div>
                            <div class="btns">
                                <button class="btn" @click="login"
                                    v-loading.fullscreen.lock="fullscreenLoading">立即登录</button>
                                <button class="btn" @click="toRegister">新用户注册</button>
                            </div>
                        </div>
                    </div>
                    <div class="float1">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/float1.png)"></div>
                        </div>
                    </div>
                    <div class="float2">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/float2.png)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="login_right"></div>
    </div>
</template>

<script>
import { getBase, denglu, getWxUrl, getLoginBack, getUserInfo, getWebrtcParams } from "@/js/request"
import { ElMessage } from 'element-plus'


// @ is an alias to /src
export default {
    name: 'Home',
    components: {

    },
    data() {
        return {
            base: {},
            baseUrl: '',
            wxLoginUrl: '',
            wxLoginCode: '',
            loginType: 2,
            username: '',
            username_sw: false,
            password: '',
            password_sw: false,
            errorts: '',
            loginInterval: null,

            fullscreenLoading: false,

        }
    },
    methods: {
        toIndex() {
            this.$router.push('/');
        },
        login() {
            var that = this;
            that.fullscreenLoading = true;
            this.username_sw = false;
            this.password_sw = false;
            if (this.username == '') {
                this.username_sw = true;
            } else {
                this.username_sw = false;
            }

            if (this.password == '') {
                this.password_sw = true;
            } else {
                this.password_sw = false;
            }
            denglu({ "codeKey": "test", "codeValue": "test", "password": that.password, "userName": that.username }).then(res => {
                that.fullscreenLoading = false
                console.log(res);
                // that.errorts = '登录成功，跳转至问卷...';
                // ElMessage({
                //     message:that.errorts,
                //     type: 'success',
                // })  

                //设置登录token            
                this.$cookies.set("token", res.data.accessToken, 60 * 30);
                // this.$router.replace('/index?wen=1');
                const modelKey = window.localStorage.getItem('modelKey');
                getUserInfo(modelKey).then(res => {
                    window.localStorage.removeItem('modelKey');
                    const webrtcId = window.localStorage.getItem('webrtcId');
                    let webrtcIndex = window.localStorage.getItem('webrtcIndex');
                    if(webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                        webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == ""){
                        const depId = 3;
                        const jumpJrl = res.data.url + "&depId=" + depId;
                        console.log("jumpJrl=", jumpJrl)
                        window.location.href = jumpJrl;
                    }else{
                        webrtcIndex = webrtcIndex.split("?")[0];
                        
                        getWebrtcParams(webrtcId).then(WebrtcRes => {
                            const webrtcParams = WebrtcRes.data[webrtcIndex];
                            if(webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null){
                                this.telConnected = true;
                                return;
                            }
                            const depId = webrtcParams.articleFields[5].fieldValue;
                            const jumpJrl = res.data.url + "&depId=" + depId;
                            window.location.href = jumpJrl;
                        });
                    }
                }).catch(res => {
                    if (res.response.status == 401) {                    
                        this.$router.push('/home?value='+encodeURIComponent(modelKey));
                    } else {
                        alert(res.response.statusText);
                    }
                });


            }).catch(res => {
                that.fullscreenLoading = false
                if (Array.isArray(res.response.data.message)) {
                    that.errorts = res.response.data.message[0][0];
                } else {
                    that.errorts = res.response.data.message;
                }
                ElMessage({
                    message: that.errorts,
                    type: 'warning',
                })
                console.log(res);
            });

        },
        toRegister() {
            this.$router.replace('/newuser');
        },
        changeType(v){
            this.loginType=v;
            if(this.loginType==1){
                this.loginInterval = setInterval(() => {
                    getLoginBack(this.wxLoginCode).then(res => {
                        console.log(res);
                        clearInterval(this.loginInterval);
                        //设置登录token            
                        this.$cookies.set("token", res.data.accessToken, 60 * 30);
                        // this.$router.replace('/index?wen=1');
                        const modelKey = window.localStorage.getItem('modelKey');
                        getUserInfo(modelKey).then(res => {
                            window.localStorage.removeItem('modelKey');
                            const webrtcId = window.localStorage.getItem('webrtcId');
                            let webrtcIndex = window.localStorage.getItem('webrtcIndex');
                            if(webrtcId == null || webrtcId == undefined || webrtcId == "" ||
                                webrtcIndex == null || webrtcIndex == undefined || webrtcIndex == ""){
                                const depId = 3;
                                const jumpJrl = res.data.url + "&depId=" + depId;
                                console.log("jumpJrl=", jumpJrl)
                                window.location.href = jumpJrl;
                            }else{
                                webrtcIndex = webrtcIndex.split("?")[0];
                                
                                getWebrtcParams(webrtcId).then(WebrtcRes => {
                                    const webrtcParams = WebrtcRes.data[webrtcIndex];
                                    if(webrtcParams == null || webrtcParams == undefined || webrtcParams.articleFields == null){
                                        this.telConnected = true;
                                        return;
                                    }
                                    const depId = webrtcParams.articleFields[5].fieldValue;
                                    const jumpJrl = res.data.url + "&depId=" + depId;
                                    window.location.href = jumpJrl;
                                });
                            }
                        }).catch(res => {
                            if (res.response.status == 401) {                    
                                this.$router.push('/home?value='+encodeURIComponent(modelKey));
                            } else {
                                alert(res.response.statusText);
                            }
                        });
                        
                    }).catch(res=>{
                        console.log(res);
                        if(res.response.data.code==10020){                            
                            clearInterval(this.loginInterval);    
                            this.$router.replace('/newuser?openid='+res.response.data.message);
                        }
                    });
                }, 1000);
            }
            else{
                clearInterval(this.loginInterval);
            }
        }
    },
    mounted() {
        this.$root.showRight();
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });

        getWxUrl().then(res => {
            this.wxLoginUrl = res.data.url;
            this.wxLoginCode = res.data.code;
        });
    },
    unmounted() {
        clearInterval(this.loginInterval);
    }
}
</script>

<style lang="scss">
.home {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden !important;

    .login_left {
        flex: 1;
        height: 100%;
        background: url(../assets/img/login_left.jpg) no-repeat center center/cover;
        padding: 60px 260px;
        box-sizing: border-box;

        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }

        .centerbox {
            position: relative;
            width: 100%;

            .titlebox {
                text-align: left;
                margin-top: 90px;
                line-height: 80px;

                .atitle {
                    @include sys-font(60px, $acolor, bold);
                }

                .btitle {
                    @include sys-font(48px, $acolor);
                }
            }

            .login {
                position: absolute;
                top: 0;
                right: -500px;

                .login_main {
                    width: 480px;
                    height: 600px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
                    border-radius: 30px;
                    padding: 40px 80px;
                    box-sizing: border-box;

                    .catebox {
                        display: flex;
                        height: 80px;
                        border-bottom: 2px solid #EEEEEE;

                        .cate {
                            flex: 1;
                            @include sys-font(26px, #999);
                            line-height: 80px;
                            text-align: center;
                            position: relative;
                            cursor: pointer;

                            &.active {
                                color: #000;

                                &::after {
                                    background-color: $acolor;
                                }
                            }

                            &::after {
                                content: '';
                                width: 100%;
                                height: 4px;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                background-color: transparent;
                            }
                        }
                    }

                    .formbox {
                        @include sys-flex();
                        margin-top: 80px;

                        .qrbox {
                            width: 240px;
                            height: 240px;
                            border: 2px solid #EEEEEE;
                            @include sys-flex();
                            @include sys-picbox(200px, 200px);
                        }

                        .tishi {
                            display: flex;
                            align-items: center;
                            margin-top: 34px;
                            @include sys-font(20px, #000);
                            @include sys-picbox(20px, 20px);

                            .picbox {
                                flex: none;
                                margin-right: 20px;
                            }

                            .txt {
                                @include sys-text-hide(1);

                                span {
                                    color: $acolor;
                                }
                            }
                        }

                        .errorts {
                            color: #ff0000;
                        }

                        .input {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 60px;
                            background: #F8FAFE;
                            border-radius: 6px;
                            padding: 0 0 0 30px;
                            margin-bottom: 20px;
                            box-sizing: border-box;
                            @include sys-picbox(18px, 20px);

                            &.active {
                                border: 1px solid #ff0000;
                            }

                            .picbox {
                                flex: none;
                                margin-right: 20px;
                            }

                            input {
                                outline: none;
                                background: transparent;
                                flex: 1;
                                height: 60px;
                                @include sys-font(20px, #333);

                                &::-webkit-input-placeholder {
                                    /* WebKit, Blink, Edge */
                                    color: #B2B7C6;
                                }

                                &:-moz-placeholder {
                                    /* Mozilla Firefox 4 to 18 */
                                    color: #B2B7C6;
                                }

                                &::-moz-placeholder {
                                    /* Mozilla Firefox 19+ */
                                    color: #B2B7C6;
                                }

                                &:-ms-input-placeholder {
                                    /* Internet Explorer 10-11 */
                                    color: #B2B7C6;
                                }
                            }
                        }

                        .btns {
                            width: 100%;
                            padding-top: 10px;

                            .btn {
                                @include sys-flex();
                                font-size: 20px;
                                margin-bottom: 20px;
                                cursor: pointer;

                                &:first-child {
                                    @include sys-boxsize(100%, 60px, 6px, $acolor);
                                    color: #fff;
                                }

                                &:last-child {
                                    @include sys-boxsize(100%, 60px, 6px);
                                    border: 2px solid $acolor;
                                    color: $acolor;
                                }
                            }
                        }
                    }
                }

                .float1,
                .float2 {
                    position: absolute;
                    @include sys-picbox(136px, 58px);
                }

                .float1 {
                    top: 0;
                    left: -300px;
                }

                .float2 {
                    left: 50%;
                    bottom: -120px;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .login_right {
        flex: none;
        width: 500px;
        height: 100%;
        background: url(../assets/img/login_right.jpg) no-repeat center center/cover;
    }
}
</style>